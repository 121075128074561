import { useEffect, useRef, useState } from "react";
import useListenMessage from "../../hooks/useListenMessage";
import postMessage from "../../utils/postMessage";
import { TableFlowImporterProps } from "./types";
import "./style/tableflow-importer.css";

export default function TableFlowImporter({
  importerId,
  hostUrl,
  isModal = true,
  modalIsOpen = true,
  modalOnCloseTriggered = (importComplete?: boolean) => {},
  modalCloseOnOutsideClick,
  template,
  darkMode = false,
  primaryColor = "#7a5ef8",
  metadata,
  className,
  onComplete,
  waitOnComplete,
  customStyles,
  showDownloadTemplateButton,
  skipHeaderRowSelection,
  cssOverrides,
  schemaless,
  schemalessReadOnly,
  schemalessDataTypes,
  trimSpaces,
  language,
  customTranslations,
  showUploadAnotherFileButton,
  ...props
}: TableFlowImporterProps) {
  const ref = useRef(null);
  const iframeRef = useRef<any>(null);
  const current = ref.current as any;

  const isImportComplete = useRef(false);
  const [uploaderUrl, setUploaderUrl] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isModal && current) {
      if (modalIsOpen) {
        current?.showModal?.();
        isImportComplete.current = false;
      } else current?.close?.();
    }
  }, [isModal, modalIsOpen, current]);

  const baseClass = "TableFlowImporter";
  const themeClass = darkMode && `${baseClass}-dark`;
  const domElementClass = [`${baseClass}-${isModal ? "dialog" : "div"}`, themeClass, className].filter((i) => i).join(" ");

  importerId = importerId === undefined || importerId?.trim() === "" ? "0" : importerId;

  const params = {
    importerId: importerId || "",
    isModal: isModal ? "true" : "false",
    modalIsOpen: "true",
    template: parseObjectOrStringJSON("template", template),
    darkMode: darkMode.toString(),
    primaryColor,
    metadata: parseObjectOrStringJSON("metadata", metadata),
    onComplete: onComplete ? "true" : "false",
    waitOnComplete: parseOptionalBoolean(waitOnComplete),
    customStyles: parseObjectOrStringJSON("customStyles", customStyles),
    cssOverrides: parseObjectOrStringJSON("cssOverrides", cssOverrides),
    showDownloadTemplateButton: parseOptionalBoolean(showDownloadTemplateButton),
    skipHeaderRowSelection: parseOptionalBoolean(skipHeaderRowSelection),
    schemaless: parseOptionalBoolean(schemaless),
    schemalessReadOnly: parseOptionalBoolean(schemalessReadOnly),
    schemalessDataTypes: parseOptionalBoolean(schemalessDataTypes),
    trimSpaces: parseOptionalBoolean(trimSpaces),
    language: language || "en",
    customTranslations: parseObjectOrStringJSON("customTranslations", customTranslations),
    showUploadAnotherFileButton: parseOptionalBoolean(showUploadAnotherFileButton),
  };
  const defaultImporterUrl = "https://importer.tableflow.com";
  const backdropClick = () => {
    if (modalCloseOnOutsideClick) {
      modalOnCloseTriggered(isImportComplete.current);
      isImportComplete.current = false;
    }
  };

  useEffect(() => {
    // const searchParams = new URLSearchParams(params);
    setUploaderUrl(`${hostUrl ? hostUrl : defaultImporterUrl}`);
    postMessage(iframeRef.current, { importerId, params, type: "importer-parameters" });
  }, [params]);

  useListenMessage({
    importerId,
    onComplete: (data) => {
      isImportComplete.current = true;
      if (onComplete) onComplete(data);
    },
    modalOnCloseTriggered: () => {
      modalOnCloseTriggered(isImportComplete.current);
    },
    onReload: () => {
      isImportComplete.current = false;
    },
    onStarted: () => {
      // setIsLoading(false);
    },
    onPostMessageSubscribe: () => {
      setIsLoading(false);
    },
  });

  const elementProps = {
    ref,
    ...(isModal ? { onClick: backdropClick } : {}),
    className: domElementClass,
    ...props,
  };

  return isModal ? (
    <dialog {...elementProps}>
      <iframe ref={iframeRef} className={isLoading ? "visible" : ""} src={uploaderUrl} />
    </dialog>
  ) : (
    <div {...elementProps}>
      <iframe className={isLoading ? "" : "visible"} ref={iframeRef} src={uploaderUrl} />
    </div>
  );
}

// Allows for the user to pass in JSON as either an object or a string
const parseObjectOrStringJSON = (name: string, param?: Record<string, unknown> | string): string => {
  if (typeof param === "undefined") {
    return "";
  }

  let parsedObj: Record<string, unknown> = {};

  if (typeof param === "string") {
    try {
      parsedObj = JSON.parse(param);
    } catch (e) {
      console.error(
        `The '${name}' prop is not a valid JSON string. This prop can either be a JSON string or JSON object. Please check the documentation for more details.`
      );
      return "";
    }
  } else {
    parsedObj = param;
  }

  // Replace % symbols with %25
  for (const key in parsedObj) {
    if (typeof parsedObj[key] === "string") {
      parsedObj[key] = (parsedObj[key] as string).replace(/%(?!25)/g, "%25");
    }
  }

  return JSON.stringify(parsedObj);
};

const parseOptionalBoolean = (val?: boolean) => {
  return typeof val === "undefined" || val === null ? "" : val ? "true" : "false";
};
