import { useEffect } from "react";


type MessageType = "start" | "complete" | "close" | "reload";

type MessageData = {
  source: string;
  id: string;
  type: MessageType;
  importerId: string;
  data?: any;
};

type UseListenMessageProps = {
  importerId?: string;
  onComplete?: (data: any) => void;
  modalOnCloseTriggered?: (importComplete?: boolean) => void;
  onReload?: () => void;
  onStarted?: () => void;
  onPostMessageSubscribe: () => void;
};

export default function useListenMessage({
  importerId,
  onComplete,
  modalOnCloseTriggered,
  onReload,
  onStarted,
  onPostMessageSubscribe,
}: UseListenMessageProps) {
  useEffect(() => {
    let postMessages: string[] = [];

    const postHandlers = {
      complete: (messageData: MessageData) => {
        onComplete?.(messageData?.data);
        postMessages.push(messageData?.id);
      },
      close: (messageData: MessageData) => {
        modalOnCloseTriggered?.();
        postMessages.push(messageData?.id);
      },
      reload: (messageData: MessageData) => {
        onReload?.();
        postMessages.push(messageData?.id);
      },
      start: () => {
        onStarted?.();
      },
      postMessageSubscribe: (messageData: MessageData) => {
        onPostMessageSubscribe();
      },
    };

    function messageListener(e: { data: MessageData } | MessageEvent<MessageData> | null) {
      if (!e || !e?.data) return;

      const messageData = e.data;

      if (
        !messageData ||
        messageData?.source !== "tableflow-importer" ||
        !messageData?.id ||
        // checks if there is importerId and if it is different from the current importerId
        (messageData?.importerId && messageData?.importerId !== importerId) ||
        postMessages.includes(messageData.id)
      ) {
        return;
      }

      postHandlers[messageData.type]?.(messageData);
    }

    window.addEventListener("message", messageListener);
    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, [importerId]);
}